/* في ملف CardAddress.css */

.user-address-card {
    background-color: #fff;
    padding: 8px;
    height: max-content;
    border: 1px solid #e1e1e1;
    border-radius: 10px;
    box-shadow: 0 0px 4px rgba(0, 0, 0, 0.1);
    transition: box-shadow 0.3s ease;
  }
  .react-tel-input .form-control {
    width:100% !important;
  }
  .user-address-card:hover {
    box-shadow: 0 1px 8px rgba(0, 0, 0, 0.2);
  }
  
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
  }
  
  .title {
    font-size: 20px;
    font-weight: bold;
    color: #333;
  }
  
  .actions {
    display: flex;
    width: 125px !important;
    min-width: 125px !important;
    align-items: center;
  }
  
  .edit-link {
    text-decoration: none;
    color: #007bff;
    margin-left: 10px;
    font-size: 16px;
    transition: color 0.3s ease;
  }
  
  .edit-link:hover {
    color: #0056b3;
  }
  
  .delete {
    display: flex;
    align-items: center;
    font-size: 16px;
    color: #e74c3c;
  }
  
  .delete-icon {
    margin-left: 5px;
  }
  
  .info {
    margin-bottom: 10px;
  }
  
  .info-item {
    font-size: 16px;
    color: #555;
    margin-bottom: 5px;
  }
  
  .info-label {
    font-weight: bold;
    margin-right: 5px;
  }
  
  .phone {
    color: #555;
    font-size: 16px;
    margin-top: 25px;
    display: flex;
  }
  
  .phone-label {
display: inline-block;
  }
  