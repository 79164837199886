.delete-color-confirmation {
  position: fixed;
  top: 0;
  left: 50%;
  transform: translate(-50%, 0);
  width: 400px;
  padding: 25px 25px;
  background: #fff;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
}
.delete-user-btn {
  position: absolute !important;
  top: 30% !important;
  right: 1%;
  background: #ff0000 !important;
  padding: 5px 6px !important;
  color: #fff !important;
  cursor: pointer !important;
}
.remove-size {
  margin: 17px 6px 0 !important;
  padding: 5px;
  position: relative;

  width: max-content;
  height: 46px;
  padding: 0 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  box-shadow: 0 0 7px rgb(199 55 182);
}
.delete-color-confirmation div {
  display: flex;
  justify-content: space-between;
  gap: 10px;
}
.delete-color-confirmation div button {
  padding: 8px 46px;
  border-radius: 5px;
  background: #fff;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  outline: 0;
  border: 0;
  transition: var(--transition-timing);
}

.delete-color-confirmation div button:hover {
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
}

@media (max-width: 500px) {
  .delete-color-confirmation {
    width: 250px;
  }
  .delete-color-confirmation div button {
    padding: 6px 16px;
    font-size: 15px;
  }
  .delete-color-confirmation p {
    font-size: 15px;
  }
}

.attribute-cont {
  font-size: 13px;
  color: #111;
}
.attribute-cont div:nth-child(2) {
  font-size: 13px;
  color: #777;
}
