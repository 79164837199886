.user-add-address {
    padding: 20px;
    background-color: #f7f7f7;
    border: 1px solid #ccc;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);

  }
  
  .admin-content-text {
    font-size: 24px;
    font-weight: bold;
    color: #333;
    margin-bottom: 10px;
  }
  
  .input-form,
  .input-form-area {
    width: 100%;
    padding: 10px;
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.1);

    outline: 0;
    border-radius: 7px; /* زيادة border-radius */
    font-size: 16px;
    border:1px solid ;
    border-color: #333;
    margin-top: 10px;
    transition: border-color 0.3s ease, transform 0.3s ease; /* إضافة تأثير عند التركيز */
  }

  .input-form:focus,
  .input-form-area:focus {
    border-color: var(--sandy-brown);
    opacity: 0.98; /* تكبير عند التركيز */
  }
  