footer {
    position: relative;
    width: 100%;
    border-top: 1px solid #333;
    height: auto;
    padding: 50px 100px;
    --tw-bg-opacity: 1;
    margin-top: 85px;
    background-color: rgba(249,250,251,var(--tw-bg-opacity));
}
footer .container-footer {
    width: 100%;
    display: grid;
    grid-template-columns: 2fr 1fr 1fr 1fr;
    grid-gap: 20px;
}
footer .container-footer .sec h2 {
    position: relative;
    color: var(--salmon-green);
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 15px;
}
footer .container-footer .sec p {
    color: #555;
}
footer .container-footer .sci {
    margin-top: 20px;
    display: grid;
    grid-template-columns: repeat(4,50px);
}
footer .container-footer .sci li{
    list-style: none;
}
footer .container-footer .sci li a {
    display: inline-block;
    width: 36px;
    height: 36px;
    background: #333;
    display: grid;
    align-content: center;
    border-radius: 5px;
    justify-content: center;
    text-decoration: none;
    transition: 0.5s;
}
footer .container-footer .sci li a:hover {
    background: var(--salmon-green);
}
footer .container-footer .sci li a svg {
    color: #fff;
    font-size: 20px;
}
footer .container-footer .quicklinks {
    position: relative;
}
footer .container-footer .quicklinks ul li{
    list-style: none;
}
footer .container-footer .quicklinks ul li a{
    color: #555;
    text-decoration: none;
    transition: 0.2s;
    margin-bottom: 10px;
    display: inline-block;
}
footer .container-footer .quicklinks ul li a:hover {
    color: var(--salmon-green);
}
footer .container-footer .contact .info {
    position: relative;
}
footer .container-footer .contact .info li {
    display: grid;
    grid-template-columns: 30px 1fr;
    margin-bottom: 16px;
}
footer .container-footer .contact .info li span {
    color: var(--salmon-green);
    font-size: 20px;

}
footer .container-footer .contact .info li a {
    color: #555;
    text-decoration: none;
}
.contact-privacy {
    display: flex;
    align-items: center;
    margin-top: 20px;
}
.info-privacy li  {
    list-style-type: none !important;
    display: flex;
    gap: 11px;
}
.info-privacy li::before {
    content: "" !important  ;
    width: 0 !important;
    height: 0 !important;
}
.info-privacy li a {
    color: var(--salmon-green);
}
.info-privacy li svg {
    font-size: 25px;
}
.copyrightText {
    width: 100%;
    background: #fff;
    padding: 20px 100px 30px;
    text-align: center;
    color: #555;
    border: 1px solid rgba(0,0,0,0.15);
}
@media(max-width:1099px){
    footer .container-footer {
        grid-template-columns: repeat(3,1fr);
    }
}
@media (max-width:991px){
    footer {
        padding: 40px;
    }
    footer .container-footer {
        grid-template-columns: repeat(2,1fr);
    }
    .copyrightText {
        padding: 20px 40px 30px;
    }
}
@media (max-width:768px){
    footer .container-footer {
        grid-template-columns: 1fr;
    }
    .copyrightText {
        padding: 20px 40px 30px;
        padding-bottom: 65px;
    }
}