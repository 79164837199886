/* Header */
header {
  background: var(--white);
}
.header-top,
.header-user-actions,
.desktop-navigation-menu {
  display: none;
}

.header-main {
  padding: 20px 0;
  border-bottom: 1px solid var(--cultured);
}

.header-logo {
  margin-bottom: 20px;
}

.header-logo img {
  margin: auto;
}

.header-search-container {
  position: relative;
  outline: none;
}
.header-search-container .search-field {
  font-size: var(--fs-7);
  color: var(--onyx);
  padding: 10px 15px;
  padding-right: 50px;
  border: 1px solid var(--cultured);
  border-radius: var(--border-radius-md);
  outline: none;
}
.header-search-container .search-field:focus {
  border: 1px solid var(--salmon-green);
}

.search-field::-webkit-search-cancel-button {
  display: none;
}

.search-btn {
  position: absolute;
  top: 50%;
  right: 2px;
  transform: translateY(-50%);
  color: var(--onyx);
  font-size: 18px;
  padding: 8px 15px;
  transition: color var(--transition-timing);
}
.search-btn:hover {
  color: var(--salmon-green);
}

.mobile-bottom-navigation {
  backdrop-filter: blur(33px);
  background: #ffffff8a;
  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 100vw;
  max-width: 500px;
  margin: auto;
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 5px 0;
  box-shadow: 0 0 10px hsla(0, 0%, 0%, 0.25);
  z-index: 5;
  margin: 0 !important;
}

.mobile-bottom-navigation .action-btn {
  position: relative;
  font-size: 26px;
  color: var(--eerie-black);
  padding: 10px;
}
.mobile-bottom-navigation .action-btn a {
  font-size: 26px;
  color: var(--eerie-black) !important;
}
.mobile-bottom-navigation .count {
  align-items: center;
  background: var(--bittersweet);
  border-radius: 20px;
  display: flex;
  height: 17px;
  justify-content: center;
  position: absolute;
  right: -4px;
  text-align: center;
  top: 8px;
  width: 17px;
}
.mobile-bottom-navigation .count span {
  -webkit-border-radius: 50%;
    -ms-webkit-radius: 50%;
    zoom: 1;
    background-color: var(--bittersweet);
    border: 2px solid transparent;
    display: block;
    font-size: 12px;
    height: 20px;
    line-height: 1.4;
    min-width: 20px;
    text-align: center;
    width: auto;
    color: #fff;
}

.mobile-navigation-menu {
  background: var(--white);
  position: fixed;
  top: 0;
  right: -100%;
  width: 100%;
  max-width: 320px;
  height: 100vh;
  padding: 20px;
  box-shadow: 0 0 10px hsla(0, 0%, 0%, 0.1);
  overflow-y: scroll;
  overscroll-behavior: contain;
  visibility: hidden;
  transition: 0.5s ease;
  z-index: 20;
}

.mobile-navigation-menu.active {
  right: 0;
  visibility: visible;
}

.menu-top {
  padding-bottom: 15px;
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 2px solid var(--cultured);
}

.menu-top .menu-title {
  color: var(--salmon-green);
  font-size: var(--fs-4);
  font-weight: var(--weight-600);
}

.menu-close-btn {
  color: var(--eerie-black);
  font-size: 22px;
}

.mobile-menu-category-list {
  margin-bottom: 30px !important;
  padding-left: 0 !important;
}

.menu-category .accordion-menu {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.mobile-menu-category-list .menu-category {
  border-bottom: 1px solid var(--cultured);
}

.mobile-menu-category-list .menu-title {
  color: var(--onyx);
  font-size: var(--fs-6);
  font-weight: var(--weight-500);
  padding: 12px 0 !important;
}

.accordion-menu > div {
  font-size: 14px;
}

.accordion-menu svg {
  color: var(--onyx);
}

.accordion-menu.active .add-icon,
.accordion-menu .remove-icon {
  display: none;
}

.accordion-menu .add-icon,
.accordion-menu.active .remove-icon {
  display: block;
}

.menu-category .submenu-category-list {
  margin-left: 10px;
  margin-right: 10px;
}

.submenu-title {
  padding: 6px 0 !important;
  font-size: var(--fs-6);
  color: var(--sonic-silver);
  font-size: var(--weight-300);
}

.submenu-title:hover {
  color: var(--davys-gray);
}

.submenu-category-list {
  max-height: 0 !important;
  overflow: hidden;
  visibility: hidden;
  transition: 0.5s ease-in-out;
  padding-left: 0;
}

.submenu-category-list.active {
  max-height: 148px !important;
  visibility: visible;
}

.menu-bottom .menu-category-list {
  margin-bottom: 20px !important;
}

.menu-bottom .menu-category {
  border-bottom: none;
}

.menu-bottom .menu-title {
  font-size: var(--fs-6);
  font-weight: var(--weight-600);
  color: var(--eerie-black);
  padding: 12px 0;
}

.accordion-menu.active .caret-back {
  transform: rotate(-0.25turn);
}

.menu-bottom .submenu-category-list {
  border: 1px solid var(--cultured);
  border-radius: var(--border-radius-md);
  padding: 0 15px;
  margin-left: 0;
  box-shadow: 0 0 10px hsla(0, 0%, 0%, 0.05);
  padding-bottom: 12px !important;
}

.menu-bottom .submenu-category:not(:last-child) {
  border-bottom: 1px solid var(--cultured);
}

.menu-social-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.menu-social-container .social-link {
  background: var(--cultured);
  color: var(--eerie-black);
  font-size: 20px;
  padding: 10px;
  border-radius: var(--border-radius-md);
}
.action-btn.logged {
  padding: 10px 10px !important;
  border-radius: 3px !important;
  width: auto !important;
  height: auto !important;
}
@media (min-width: 480px) {
  :root {
    --fs-1: 1.875rem;
  }

  .header-top {
    display: block;
    padding: 10px 0;
    border-bottom: 1px solid var(--cultured);
  }
  .header-social-container,
  .header-top-actions {
    display: none;
  }
  .header-alert-news {
    color: var(--sonic-silver);
    font-size: var(--fs-9);
    text-transform: uppercase;
  }
  .header-alert-news b {
    font-weight: var(--weight-500);
  }
  .header-main {
    padding: 25px 0;
  }
  .mobile-bottom-navigation {
    background: rgb(255 255 255 / 54%);
    backdrop-filter: blur(33px);
    border-top-left-radius: var(--border-radius-md);
    border-top-right-radius: var(--border-radius-md);
  }
}

@media (min-width: 570px) {
  .header-top .container,
  .header-main .container {
    display: flex !important;
    justify-content: space-between !important;
    align-items: center !important;
  }
  .header-logo {
    margin-bottom: 0;
  }
  .header-top-actions {
    display: flex !important;
    align-items: center !important;
    gap: 10px !important;
  }
  .header-top-actions select {
    border: none !important;
    display: block !important;
    min-width: 80px !important;
    padding: 5px 0 !important;
    font: inherit !important;
    color: var(--sonic-silver) !important;
    font-size: var(--fs-8) !important;
    text-transform: uppercase !important;
    cursor: pointer !important;
    transition: var(--transition-timing) !important;
  }
  .header-top-actions select:hover {
    color: var(--eerie-black);
  }
  .header-search-container {
    min-width: 350px !important;
  }
}

@media (min-width: 768px) {
  :root {
    --fs-1: 2.375rem;
  }
  html {
    font-size: 17px;
  }

  .container {
    max-width: 750px !important;
    margin: auto !important;
  }

  .header-main .container {
    gap: 80px !important;
  }
  .header-search-container {
    flex-grow: 1 !important;
  }
}

@media (min-width: 1024px) {
  /**
   * #HEADER 
   */
  :root {
    /**
     * typography 
     */

    --fs-1: 2.625rem;
    --fs-2: 1.125rem;
  }

  /**
   * #REUSED STYLE 
   */

  .container {
    max-width: 980px !important;
  }
  .header-social-container {
    display: flex;
    align-items: center;
    gap: 5px;
  }

  .header-social-container .social-link {
    padding: 5px;
    background: hsl(0, 0%, 95%);
    border-radius: var(--border-radius-sm);
    color: var(--sonic-silver);
    transition: var(--transition-timing);
  }

  .header-social-container .social-link:hover {
    background: var(--salmon-green);
    color: var(--white);
  }

  .header-user-actions {
    display: flex;
    align-items: center;
    gap: 15px;
  }

  .header-user-actions .action-btn {
    position: relative;
    font-size: 17px;
    color: var(--onyx);
    padding: 5px;
    box-shadow: inset 0 0 4px rgba(0, 0, 0, 0.1);
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
  }
  .header-user-actions .action-btn:hover {
    box-shadow: inset 0 0 8px rgba(0, 0, 0, 0.1);
  }
  .header-user-actions .action-btn a svg:first-child {
    font-size: 27px;
    display: flex;
  }
  #heart-desktop {
    margin-top: 1.4px;
    color: #555;
    transition: 0.2s;
  }
  #heart-desktop:hover {
    color: var(--salmon-green);
  }
  .user-icon-nav {
    border: 1px solid #777 !important;
    width: 51px !important;
    height: 41px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    transition: 0.2s !important;
  }
  .user-icon-nav:hover {
    border-color: var(--salmon-green) !important;
  }
  .user-icon-nav:hover svg {
    color: var(--salmon-green) !important;
  }

  #shopping-bag {
    color: #555 !important;
    transition: 0.2s !important;
  }
  #shopping-bag:hover {
    color: var(--salmon-green) !important;
  }
  .user-icon-nav svg {
    color: #555;
    transition: 0.2s !important;
    font-size: 25px !important;
  }
  .header-user-actions .action-btn a {
    color: var(--eerie-black) !important;
  }
  .header-user-actions .count {
    position: absolute;
    top: -2px;
    right: -3px;
    background: var(--bittersweet);
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--white);
    font-size: 11px;
    font-weight: var(--weight-500);
    /* padding: 2px 5px; */
    border-radius: 20px;
    width: 18px;
    height: 18px;
  }

  .desktop-navigation-menu {
    display: block;
  }

  .desktop-menu-category-list {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 30px;
  }

  .desktop-menu-category-list .menu-category:not(:nth-child(2)) {
    position: relative;
  }

  .desktop-menu-category-list .menu-category > .menu-title {
    position: relative;
    color: var(--onyx);
    font-size: var(--fs-7);
    font-weight: var(--weight-600);
    text-transform: uppercase;
    padding: 15px 0;
    transition: var(--transition-timing);
  }

  .desktop-menu-category-list .menu-category > .menu-title:hover {
    color: var(--salmon-green);
  }

  .desktop-menu-category-list .menu-category > .menu-title::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 2px;
    background: var(--salmon-green);
    transform: scaleX(0);
    transform-origin: left;
    transition: var(--transition-timing);
  }

  .desktop-menu-category-list .menu-category > .menu-title:hover::after {
    transform: scaleX(1);
  }

  .dropdown-panel {
    position: absolute;
    top: 100%;
    left: 0;
    background: var(--white);
    width: 100%;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 30px;
    padding: 30px;
    border: 1px solid var(--cultured);
    box-shadow: 0 3px 5px hsla(0, 0%, 0%, 0.1);
    border-radius: var(--border-radius-md);
    transform: translateY(50px);
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
    transition: var(--transition-timing);
    z-index: 999999999999 !important;
  }

  .desktop-menu-category-list .menu-category:hover > .dropdown-panel {
    transform: translateY(0);
    opacity: 1;
    visibility: visible;
    pointer-events: all;
  }

  .dropdown-panel-list .menu-title a {
    color: var(--onyx);
    font-size: var(--fs-7);
    font-weight: var(--weight-600);
    padding-bottom: 10px;
    border-bottom: 1px solid var(--cultured);
    margin-bottom: 10px;
  }

  .panel-list-item a {
    color: var(--sonic-silver);
    font-size: var(--fs-7);
    text-transform: capitalize;
    transition: var(--transition-timing);
  }

  .panel-list-item a:hover {
    color: var(--salmon-green);
  }

  .panel-list-item:not(:last-child) a {
    padding: 4px 0;
  }

  .panel-list-item:last-child {
    margin-top: 20px;
  }

  .panel-list-item img {
    width: 100%;
    height: 100px;
    border-radius: var(--border-radius-sm);
    object-fit: cover;
  }

  .dropdown-list {
    position: absolute;
    top: 100%;
    left: 0;
    width: 200px;
    background: var(--white);
    padding: 20px 0;
    border-radius: var(--border-radius-md);
    border: 1px solid var(--cultured);
    box-shadow: 0 3px 5px hsla(0, 0%, 0%, 0.1);
    transform: translateY(50px);
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
    transition: var(--transition-timing);
    z-index: 5;
  }

  .desktop-menu-category-list .menu-category:hover > .dropdown-list {
    transform: translateY(0);
    opacity: 1;
    visibility: visible;
    pointer-events: all;
  }

  .dropdown-list .dropdown-item a {
    color: var(--sonic-silver);
    font-size: var(--fs-7);
    text-transform: capitalize;
    padding: 4px 20px;
    transition: var(--transition-timing);
  }

  .dropdown-list .dropdown-item a:hover {
    color: var(--salmon-green);
  }

  .mobile-bottom-navigation {
    display: none;
  }
}

.mobile-bottom-navigation .action-btn a {
  font-size: 13px !important;
}
.dropdown-mobile #khara {
  max-width: 100px;
  overflow: hidden;
  text-overflow: ellipsis;
}


@media (max-width:500px){
  .mobile-bottom-navigation {
    max-width: 100vw;
    width: 100%;
  }
}