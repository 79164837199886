.container-icons {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 15px;
    padding: 95px 50px;

    background: #fff;
    box-shadow: 0 0 6px rgba(0,0,0,0.1);
}
.iWchow {
    line-height: normal !important;
}
@media (max-width:855px){
    .container-icons {
        padding: 95px 8px;
    }
}
@media (max-width:726px){
    .container-icons {
        grid-template-columns: 1fr 1fr;
        padding: 95px 30px;

    }
    .container-icons .card-icon:nth-child(2)::before,.container-icons .card-icon:nth-child(2)::after {
        display: none;
    }
    .container-icons .card-icon:nth-child(3){
        margin-top: 37px;
        }
}
@media (max-width:440px){
    .container-icons {
        grid-template-columns:  1fr;
        padding: 95px 30px;

    }
    .container-icons .card-icon:nth-child(2){
        margin-top: 37px;
        }
}
.container-icons .card-icon {
   text-align: center;
   position: relative;

}
.container-icons .card-icon:nth-child(2)::before {
    content: '';
    height:80%;
    width: 1px;
    opacity: 0.8;
    background: var(--salmon-green);
    position: absolute;
    top: 0;
    left: 0%;
}
.container-icons .card-icon:nth-child(2)::after {
    content: '';
    height: 100px;
    width: 1px;
    opacity: 0.8;
    background: var(--salmon-green);
    position: absolute;
    top: 0;
    right: 0%;
}
.container-icons .card-icon svg {
    font-size: 48px;
    color: var(--salmon-green);
 
}
.container-icons .card-icon .title-icon {
    font-size: 20px;
    font-weight: 600;
    margin: 0;
    margin-top: 16px;
}
.container-icons .card-icon .sub-title-icon  {
    font-size: 14px;
    font-weight: 300;
    color: rgba(107,114,128,1);
}

.reviews-container {
    width: 100%;
    background: #f3f3f3;
    padding: 25px 20px;
    margin-top: 30px;
    box-shadow: 0 0 3px rgba(0,0,0,0.1);
}
.reviews-container .title-of-section {
    position: relative;
    width: 100%;
    margin-bottom: 25px;
}
.reviews-container .title-of-section::before {
    content:'';
    position: absolute;
    bottom: -35%;
    right: 0;
    background: #111;
    width: 100px;
    height: 4px;
    border-radius: 12px;
}
.reviews-container .slide-reviews {
    margin: 45px 0px;
}
.card-review {
    width: 100%;
    border-radius: 8px;
    position: relative;
    height: 300px;
    padding: 0 20px;
    display: grid; /* تحويل إلى شبكة */
    grid-template-columns: 1fr; /* ع colon إلى ع colon واحد ليكون عنصر واحد في كل صف */
    grid-template-rows: 1fr 1fr; /* اختيارية: تقسيم العمود إلى صفين */
    background-color: #fff;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
    margin-left: 20px;
  }
.card-review .first-sec {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 180px;
}  
.card-review .stars {
    position: absolute;
    right: 6%;
    top:9%;

}
.card-review .stars img {
    mix-blend-mode: multiply;
}
.card-review .review-text {
    text-align: right;
    height:28%;
}
.card-review .user-review {
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
    height: 87px;
    justify-content: space-between;

}
.card-review .user-review svg {
    font-size: 80px;
    color: silver;
    opacity: 0.7;
}
.card-review .user-review .quote {
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
}
.card-review .user-review .quote .userName {
    margin-right: 8px;
    margin-top: 9px;
    font-size: 16px;
}

@media (max-width:500px){
    .card-review .user-review svg {
        font-size: 45px;
        color: silver;
        opacity: 0.7;
    }
    .card-review .review-text {
     
        font-size: 15px;
    }
}
.iWchow:hover {
    background: #111 !important;
}
.rec-dot_active {
    background: var(--salmon-green) !important;
    box-shadow: 0 0 8px rgba(107,114,128,0.3) !important;
}