/* ProductGallery.css */
/* تحقيق توسيط وتغطية الصور بشكل كامل */
/* ProductGallery.css */

/* ProductGallery.css */
.product-gallery-card {
  display: flex;
  position: relative;
  flex-direction: column;
}

.main-image-container {
  text-align: center;
  margin: 20px auto;
  position: relative;
}
.out-stock-circle {
  /* position: absolute;
  top: 3%;
  right: -2%; */
  margin: 12px 5px;
  padding: 3px 15px;
  color: #fff;
  font-size: 12px;
  border-radius: 3px;
  z-index: 999;
  background-color: red;
}
.lower-stock-circle {
  /* position: absolute;
  top: 3%;
  right: -2%; */

  margin: 12px 5px;
  padding: 3px 15px;
  color: #fff;
  font-size: 12px;
  border-radius: 3px;
  z-index: 999;
  background-color: orange;
}
.main-image {
  max-width: 100%; /* جعل الصورة تمتد لتصلح لحجم العنصر */
  border-radius: 9px;
  max-height: 500px;
}
.showcase-badge-admin {
  top: 0px;
  left: 1px;
  transform: rotate(0);
  text-transform: uppercase;
  font-size: 11px;
  padding: 5px 17px;
  background-color: red;
  position: absolute;
  color: #fff;
  z-index: 99999999;
}
.image-gallery-play-button {
  display: none !important;
}
.image-gallery-right-nav .image-gallery-svg,
.image-gallery-left-nav .image-gallery-svg {
  height: 73px;
  width: 40px;
}
.image-gallery-right-nav .image-gallery-svg:hover,
.image-gallery-left-nav .image-gallery-svg:hover {
  color: var(--salmon-green) !important;
}
.image-gallery .fullscreen {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 20px !important;
}
.image-gallery-content .image-gallery-slide .image-gallery-image {
  max-height: calc(88vh - 80px) !important;
}
.image-gallery .image-gallery-using-mouse {
  display: flex;
  justify-content: center;
  align-items: center;
}
.image-gallery-thumbnail .image-gallery-thumbnail-image {
  max-height: 110px !important;
  object-fit: cover !important;
  object-position: top !important;
}
.image-gallery
  .image-gallery-content.fullscreen
  .image-gallery-slide-wrapper
  .image-gallery-swipe
  .image-gallery-slides
  .image-gallery-slide {
  height: 95%;
}
.thumbnail-images {
  display: flex;
  gap: 16px;

  flex-wrap: wrap;
}
.thumbnail-image-container {
  cursor: pointer;
  width: 98px;
  padding: 2px;
  border-radius: 5px;
  background: #ffffff;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
  max-height: 80px;
  border: 2px solid transparent;
  transition: border-color 0.3s;
}

.thumbnail-image-container.active {
  border-color: var(--salmon-green); /* لون الحدود عند النقر */
  box-shadow: 0 0 12px rgba(0, 0, 0, 0.3);
  border-radius: 4px;
}

.thumbnail-image {
  width: 100%;
  height: 70px;
  padding: 4px !important;
  border-radius: 2px !important;
  object-fit: cover;
  border: 1px solid #ddd;
  object-position: center;
}
.product-swiper {
  max-width: 100%;
  margin: auto;
  overflow: hidden;
}
.swiper-wrapper {
  display: flex;
  /* justify-content: center;
  align-items: center;
  gap: 11px; */
}
.container-product {
  width: 85%;
  margin: auto;
  max-width: 1440px;
}
@media (max-width: 500) {
  .container-product {
    width: 95%;
  }
}

.slider-container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.slider-button {
  background-color: #333;
  color: #fff;
  width: 35px;
  height: 35px;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}
.two-slide-btn {
  position: absolute;
  left: 0%;
  top: 50%;
  transform: translate(0%, -50%);
}
.one-slide-btn {
  position: absolute;
  right: 0%;
  top: 50%;
  transform: translate(0%, -50%);
}
.slider-image {
  width: 100%;
  height: 500px; /* تعيين ارتفاع أقصى للصورة بناءً على الحاجة */
  margin: 0 auto;
  object-fit: contain;
  object-position: center;
  padding-left: 13px;
}

.rating-cont-single {
  display: inline-block;
  color: orange;
  align-items: center;
  margin: 0 25px;
}

.images-cont-desc {
  width: 100%;
  height: 400px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.images-cont-desc .img-box {
  background: #fff;
  border: 1px solid var(--salmon-green);
  box-shadow: 0 0 9px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  height: auto;
  padding: 6px;
  width: 25%;
}
.images-cont-desc .img-box img {
  width: 100%;
  border-radius: 8px;
  position: relative;
  height: auto;
}

.right-to-img,
.left-to-img {
  position: absolute;
  z-index: 999;
  top: 50%;
  width: 45px;
  height: 45px;
  background: #111;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  cursor: pointer;
}
.right-to-img {
  right: 2%;
}
.left-to-img {
  left: 2%;
}
