.container-else {
    width: 90%;
    margin: 100px auto;
    box-shadow: 0 0 15px rgba(0,0,0,.1);
    border: 1px solid silver;
    padding: 38px 63px;
}
.container-else h2 {
    font-size: 40px;
    margin-bottom: 26px;
    font-weight: 400;
    color: var(--salmon-green);
}
.container-else p {
    color: #515151;
    font-size: 15px;
}
.container-else p ul {
    list-style-type: none; /* إزالة نقاط القائمة الافتراضية */
  }
  .google-login {
    margin: auto;
    margin-top:20px ;
    
  }
  .container-else p ul li::before {
    content: '\2022'; /* كود Unicode للدائرة الصغيرة */
    display: inline-block;
    width: 1em; /* قيمة حسب الحاجة لتحديد المسافة بين الدائرة ونص القائمة */
    margin-right: 0.5em; /* تعديل المسافة بين الدائرة ونص القائمة */
    font-size: 1.5em;
    color: #000; /* لون الدائرة */
  }
  .furnishing {
    width: 70%;
    text-align: center;
  }
  .furnishing p{
    font-size: 17px;
}
@media (max-width:768px){
    .container-else {
        width: 96%;
        margin: 100px auto;
        box-shadow: 0 0 9px rgba(0,0,0,.1);
        border: 1px solid silver;
        padding: 38px 23px;
    }
}
@media (max-width:488px){
    .container-else p {
        color: #515151;
        font-size: 12px;
    }
}