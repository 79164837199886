@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700;800&display=swap");
:root {
  /*
   * colors
  */

  --spanish-gray: hsl(0, 0%, 60%);
  --sonic-silver: hsl(0, 0%, 47%);
  --eerie-black: hsl(0, 0%, 13%);
  --main-color: #4b9701;
  --salmon-green: #587f31;
  /* --sandy-brown: hsl(29, 90%, 65%); */
  --sandy-brown: #669d2f;
  --bittersweet: #4b9701;
  --ocean-green: hsl(152, 51%, 52%);
  --davys-gray: hsl(0, 0%, 33%);
  --cultured: hsl(0, 0%, 93%);
  --white: hsl(0, 100%, 100%);
  --onyx: hsl(0, 0%, 27%);

  /**
   * typography
   */

  --fs-1: 1.563rem;
  --fs-2: 1.375rem;
  --fs-3: 1.25rem;
  --fs-4: 1.125rem;
  --fs-5: 1rem;
  --fs-6: 0.938rem;
  --fs-7: 0.875rem;
  --fs-8: 0.813rem;
  --fs-9: 0.75rem;
  --fs-10: 0.688rem;
  --fs-11: 0.625rem;

  --weight-300: 300;
  --weight-400: 400;
  --weight-500: 500;
  --weight-600: 600;
  --weight-700: 700;

  /**
   * border-radius
   */

  --border-radius-md: 10px;
  --border-radius-sm: 5px;

  /**
   * transition 
   */

  --transition-timing: 0.2s ease;
}

/*-----------------------------------*\
  #RESET
\*-----------------------------------*/

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

a {
  text-decoration: none !important;
  color: inherit;
}

li {
  list-style: none !important;
}

button {
  background: none;
  font: inherit;
  border: none;
  cursor: pointer;
}
.btn-disabled {
  opacity: 0.8 !important;
  pointer-events: none !important;
}
.btn-primary {
  background: var(--salmon-green);
  color: var(--white);
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  font-size: var(--fs-7);

  text-transform: uppercase;
  padding: 4px 16px;
  -webkit-border-radius: var(--border-radius-sm);
  border-radius: var(--border-radius-sm);
  -webkit-transition: var(--transition-timing);
  -o-transition: var(--transition-timing);
  transition: var(--transition-timing);
  font-weight: 400;
}
.btn-primary:hover {
  background-color: var(--eerie-black);
}
img,
button,
a {
  display: block;
}

span {
  display: inline-block;
}

html {
  overscroll-behavior: contain;
}

input {
  display: block;
  width: 100%;
  font: inherit;
}

input::placeholder {
  font: inherit;
}

body {
  background: #fff;
  overflow-x: hidden !important;
}
.drop-down-menu_EMFQP {
  z-index: 99999999999999999 !important;
}
/**
 * scrollbar style
 */

body::-webkit-scrollbar {
  width: 15px;
}

body::-webkit-scrollbar-track {
  background: var(--white);
  border-left: 1px solid var(--cultured);
}

body::-webkit-scrollbar-thumb {
  background: hsl(0, 0%, 80%);
  border: 3px solid var(--white);
  border-radius: 10px;
}

body::-webkit-scrollbar-thumb:hover {
  background: hsl(0, 0%, 70%);
}

/*-----------------------------------*\
  #REUSED STYLE
\*-----------------------------------*/

.container {
  padding: 0 15px;
}

.has-scrollbar {
  padding-bottom: 5px !important;
}

.has-scrollbar::-webkit-scrollbar {
  width: 12px !important; /* for vertical scroll */
  height: 12px !important; /* for horizontal scroll */
}

.has-scrollbar::-webkit-scrollbar-thumb {
  background: transparent !important;
  border: 3px solid var(--white) !important;
  border-radius: 20px !important;
}

.has-scrollbar:hover::-webkit-scrollbar-thumb {
  background: hsl(0, 0%, 90%) !important;
}

.has-scrollbar::-webkit-scrollbar-thumb:hover {
  background: hsl(0, 0%, 80%) !important;
}
.m-0 {
  margin: 0;
}
.disabled-btn {
  opacity: 0.8;
  pointer-events: none;
}
/* The min height */
.min-height-e {
  min-height: 670px;
}
/* End Min Height */
.p-0 {
  padding: 0;
}
/* @font-face {
  font-family: Montserrat;
  src: url("./fonts/Montserrat-Arabic-Regular.ttf");
}
@font-face {
  font-family: Tajawala;
  src: url("./bold-tajawal.ttf");
  font-display: swap;
} */

.font-just {
  font-family: "Cairo", "Poppins";
}
.font {
  font-family: "Cairo", "Poppins";
  background-color: #f9f9f9;
}
.hieght-search {
  height: 80vh;
}
.logo {
  height: 46px;
  width: 50px;
}
.login-img {
  height: 20px;
  width: 20px;
}
.nav-text {
  color: #ffffff;
  font-size: 12px;
  font-weight: bold;
}
.ant-input-search {
  width: 100%;
}
.sub-tile {
  color: #272727;
  font-size: 21px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 28px;
}
.slider-bg {
  height: 650px;
  position: relative;
}
@media (max-width: 1072px) {
  .slider-bg {
    height: 520px !important;
  }
}
@media (max-width: 768px) {
  .slider-bg {
    height: 420px !important;
  }
}
@media (max-width: 720px) {
  .slider-bg {
    height: 340px !important;
  }
}
@media (max-width: 468px) {
  .slider-bg {
    height: 250px !important;
  }
}
.slider-bg::before {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.2);
}

.slider-cont div {
  position: absolute;
  top: 50%;
  right: 50%;
  text-align: start;
  background: rgba(255, 255, 255, 0.7);
  padding: 111px 55px;
  transform: translate(50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 19px;
  border-radius: 23px;
  color: var(--eerie-black);
  animation: 0.5s forwards ani2;
}

.slider-cont div .slider-title {
  font-size: 35px;
  font-weight: bolder;
  text-align: center;
}
@media (max-width: 1380px) {
  .slider-cont div {
    width: 64%;
  }
}
@media (max-width: 1080px) {
  .slider-cont div {
    width: 75%;
  }
}
@media (max-width: 778px) {
  .slider-cont div {
    width: 75%;
    padding: 35px 10px;
  }
  .slider-cont div .slider-title {
    font-size: 25px;
  }
}
@media (max-width: 420px) {
  .slider-cont div .slider-title {
    font-size: 19px;
  }
  .slider-cont div a {
    font-size: 12px;
  }
}
.carousel-inner {
  overflow: hidden;
  position: relative;
  width: 95% !important;
  margin: 21px auto;
  border-radius: 17px;
  box-shadow: 0 6px 16px rgba(0,0,0,0.2);
}
.slider-cont div a {
  background: var(--salmon-green);
  padding: 12px 10px;
  color: var(--white);
  outline: 0;
  border: 0;
  border-radius: 6px 0 6px 0;
  transition: var(--transition-timing);
}
.slider-cont div a:hover {
  background: var(--eerie-black);
}
.slider-background2 {
  background: url("./images/banner-k-1.jpeg");
  background-size: cover; /* تغطية المربع الخلفي بالكامل */
  background-repeat: no-repeat;
  background-position: right;
}
.slider-background4 {
  background: url("./images/banner-k-2.jpeg");
  background-size: cover; /* تغطية المربع الخلفي بالكامل */
  background-position: right;
}
.slider-background3 {
  background-position: center;
  background: url("./images/bannerCanv1.jpeg");
  background-size: cover; /* تغطية المربع الخلفي بالكامل */
}

.allCard {
  height: 240px;
  position: relative;
  align-items: center;
  justify-content: center;
  flex-direction: column !important;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  padding-top: 25px !important;
  border-radius: 15px 15px 0 0;
  cursor: pointer;
  transition: 0.3s linear, height 0.4s;
  padding-bottom: 10px;
}
.allCard::before {
  content: "";
  position: absolute;
  bottom: 0;
  right: 0;
  width: 0%;
  height: 4px;
  background: var(--main-color);
  transform-origin: right;
  transition: 0.3s linear;
}
.allCard:hover::before {
  width: 100%;
  transform-origin: left; /* تغيير هذه القيمة إلى left */
}
.allCard:hover {
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
  margin-top: -10px !important;
}
.allCard div {
  margin: auto;
  text-align: center;
  width: 98%;
}
.allCard:hover .category-card-text {
  opacity: 0;
  visibility: hidden;
  height: 0;
}
.allCard .title-hover {
  transform: scaleX(0);
  position: absolute;
  z-index: 9999;
  background: #fff;
}
.allCard .title-hover:hover {
  background: #fff;
  color: #272727;
}
.allCard:hover .title-hover {
  top: 50%;
  left: 50%;
  font-size: 14px;
  color: #fff;
  background: var(--main-color);
  border-radius: 15px;
  padding: 8px 16px;
  transition: 0.3s linear;
  transform: scaleX(1);
  transform: translate(-50%, -50%);
}
.allCard:hover .category-card-img {
  scale: 0.98;
  overflow: hidden;
  opacity: 0.9;
}
.category-card-img {
  max-width: 87%;
  margin: auto;
  max-height: 180px !important;
  object-fit: cover !important;
  object-position: center !important;
  border-radius: 7px;
  transition: 0.3s linear;
  min-height: 160px !important;
}

.category-card-text {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-weight: bold;
  height: 30px;
  text-align: center;
  transition: 0.3s;
  width: 100%;
}
@media (max-width: 950px) {
  .category-card-img {
    max-width: 85%;
  }
  .allCard {
    height: 275px;
  }
}
@media (min-width: 992px) {
  .col-cat-home.col-lg-2 {
    width: 20% !important;
  }
}

.select-sub-cat {
  font-size: 17px;
  background: #fff;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.1);
  color: #494c4d;
  border-radius: 4px;
  border: 0;
  outline: 0;
  height: 40px;
  width: 55%;
}
.select-sub-cat:focus {
  opacity: 0.9;
}
.desc-cont {
  background: #fff;
  padding: 15px;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  margin-bottom: 60px;
}
.desc-cont h4 {
  font-size: 26px;
  font-weight: bold;
  padding-top: 15px;
  margin-bottom: 25px;
}
.desc-cont div {
  font-size: 15px;
  color: #555550;
  padding-bottom: 12px;
}
.select-sub-cat option {
  font-size: 15px;
}
/* products card style */

.card-title {
  color: #555550;

  font-size: 16px;
  letter-spacing: 0;
  line-height: 20px;
  text-align: right;
}
.card-price {
  color: #272727;
  font-size: 21px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 28px;
}
.card-currency {
  color: #555550;
  font-size: 18px;
  letter-spacing: 0;
  line-height: 24px;
}
.card-rate {
  color: #ffc107;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 19px;
}
/* end of card style */
.shopping-now {
  padding: 5px;
  height: 35px;
  width: 90px;
  color: #272727;
  text-decoration: none;
  text-align: center;
  border: 1px solid #272727;
  border-radius: 14px;
}

.shopping-now:hover {
  background-color: #272727;
  border: 1px solid #272727;
  color: #f9f9f9;
  border-radius: 14px;
  cursor: pointer;
}

/* discount setion */
.discount-backcolor {
  height: 130px;
  border-radius: 8px;
  background: radial-gradient(circle, #767676 0%, #4d4f50 100%, #494c4d 100%);
}
.discount-title {
  color: #ffffff;
  font-size: 21px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 39px;
}
.dicount-img {
  max-height: 130px;
  max-width: 80%;
}
/* end discount */

/* foter */
.footer-background {
  background-color: #ffffff;
}

.footer {
  width: 100%;
}
.footer-shroot {
  color: #979797;
  font-size: 13px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 18px;
  cursor: pointer;
}

.footer-phone {
  color: #979797;
  font-size: 13px;
  font-weight: bold;
  text-align: right;
  cursor: pointer;
}
/* end footer */

/* card filter style */
.search-count-text {
  color: #979797;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 20px;
  text-align: right;
}
.card-filter {
  background-color: #ffffff;
  border-radius: 10px;
  padding: 15px;
  width: 170px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}

.card-filter-item {
  font-size: 12px;
  font-weight: 400;
  padding: 10px;
}
.card-filter-item:hover {
  background-color: #272727;
  color: #ffffff;
}
.img-cover {
  object-fit: cover;
}
.active {
  color: orange;
}
/* end card filter */

/* products details page */
.product-gallary-card {
  height: 470px !important;
  width: 370px;
  border-radius: 29px;
  background-color: #ffffff;
}
.cat-text {
  color: var(--bittersweet);
  font-size: 15px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 23px;
  text-align: right;
}

.cat-title {
  color: #434343;
  font-size: 24px;
  font-weight: 600;
  text-align: right;
}

.barnd-text {
  color: #555550;
  font-size: 18px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 24px;
}

.color {
  cursor: pointer;
  height: 38px;
  border: 1px solid #111;
  width: 38px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  transition: 0.3s;
}
.color.act::after {
  content: "";
  width: 100%;
  height: 100%;
  border-radius: 50%;
  position: absolute;
  top: 0;
  left: 0;
  animation: changeBackground 150ms ease-in-out forwards;
}

@keyframes changeBackground {
  to {
    background: rgba(0, 0, 0, 0.5);
  }
}

.color svg {
  z-index: 9999999999999;
  color: #fff;
  animation: opacityImageAnimation 150ms ease-in-out forwards;
  opacity: 0;
}
@keyframes opacityImageAnimation {
  to {
    opacity: 1;
  }
}

.product-description {
  color: #555550;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0;
}
.product-contact-container {
  font-size: 16px;
  color: #868686;
  display: flex;
  flex-direction: column;
  gap: 4px;
  font-weight: 500;
  margin-bottom: 15px;
}
.price-product-single {
  margin: 1px auto;
  font-size: 22px;
  font-weight: 400;
  color: var(--sandy-brown);
}
.product-price {
  height: 46px;
  width: 150px;
  text-align: center;
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 9px;
  color: #555550;
  font-size: 12px;
  font-weight: bold;
  background-color: #ffffff;
}

.product-cart-add {
  cursor: pointer;
  font-weight: 600;
  text-align: center;
  height: 50px !important;
  width: 208px !important;
  gap: 13px;
  border-radius:9px;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--salmon-green);
  color: #ffffff;
  font-size: 15px;
}
.btn-action-card:hover .to-new-url {
  color: #fff !important;
}
.product-cart-check {
  cursor: pointer;
  text-align: center;
  height: 50px !important;
  width: 100% !important;
  gap: 13px;
  border-radius: 9px;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--salmon-green);
  color: #ffffff;
  font-size: 15px;
}
.product-cart-add:hover {
  opacity: 0.9;
}
.product-cart-add:active {
  filter: brightness(90%);
}
/* products gallary slide style */
.image-gallery-slide {
  width: 372px;
  height: 470px;
  border-radius: 29px;
}
/* end gallary */
/* end products page */

/* pagination */
.page-link {
  background-color: #f9f9f9 !important;
  color: #272727 !important;
  border-radius: 3px !important;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.1);
  margin: 0 5px;
  --bs-pagination-focus-box-shadow: 0 0 0 0.25rem rgba(228, 197, 37, 0.25);
}

.page-link:hover {
  background-color: var(--salmon-green) !important;
  color: white !important;
  border: solid 1px 272727 !important;
}
/* end pagination */

/* category header */
.cat-header {
  height: 100%;
  background-color: white;
  display: flex;
  align-items: center;
  padding: 0 12px;
  flex-wrap: wrap;
  box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.2);
}
.category-product-text {
  margin-bottom: 9px;
  font-size: 15px;
  font-weight: 800;
  color: hsl(120, 62%, 47%);
}
.pagination-wrap {
  flex-wrap: wrap;
  gap: 6px;
}
.tox-notifications-container {
  max-height: 0 !important;
  overflow: hidden !important;
}
.cat-text-header {
  color: #000000;

  font-size: 18px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 23px;
  padding: 0 10px;
  padding-top: 10px;
  padding-bottom: 11px;
  border-bottom: 3px solid transparent;

  margin-left: 17px;
  cursor: pointer;
}
.cat-text-header:hover {
  color: var(--main-color);
  border-bottom: 3px solid var(--main-color);
}

/* end categoery header */

/* login and register style */

.user-input {
  box-sizing: border-box;
  height: 37px;
  width: 375px;
  border: 1px solid #979797;
  border-radius: 8px;
}
.title-login {
  color: #555550;
  font-size: 21px;
  font-weight: 800;
  letter-spacing: 0;
  line-height: 28px;
  text-align: right;
}
.btn-login {
  height: 46px;
  width: 374px;
  border-radius: 9px;
  border: none;
  color: white;
  transition: var(--transition-timing);
  background-color: var(--salmon-green);
}
.btn-login:hover {
  background: #111;
}

/* end login and register */

/* cart page */
.cart-title {
  color: #555550;
  font-size: 21px;
  font-weight: 800;
  letter-spacing: 0;
  line-height: 28px;
  text-align: right;
}
.cart-item-body {
  height: auto;
  width: 100%;
  border-radius: 20px;
  background-color: #ffffff;
  padding: 10px;
  box-shadow: 0 0 7px rgba(0, 0, 0, 0.1);
  margin-bottom: 10px;
}
.cart-item-body-admin {
  height: auto;
  width: 100%;
  padding: 10px 33px !important;
  border-radius: 20px;
  background-color: #ffffff;
  padding: 10px;
  box-shadow: 0 0 7px rgba(0, 0, 0, 0.1);
  margin-bottom: 10px;
}
.cart-item-body .imgCover {
  width: 100px;
  height: 120px;
  object-fit: cover;
  border-radius: 12px;
  margin: 10px;
  margin-left: 15px;
  object-position: center;
}
.copon-input {
  box-sizing: border-box;
  height: 46px;
  width: 90%;
  border: 1px solid #979797;
  font-size: 12px;
  font-weight: normal;
}
.copon-btn {
  cursor: pointer;
  border: none;
  height: 46px;
  padding-top: 5px;
  border-radius: 5px !important;
  padding-bottom: 5px;
  padding-right: 10px;
  padding-left: 10px !important;
  border-radius: 0 !important;
  background-color: #272727;
  color: #ffff !important;
  font-size: 12px;
  font-weight: normal;
}

.cart-checkout {
  height: 200px;
  width: 100%;
  border-radius: 20px;
  background-color: #ffffff;
}

/* cart end */

/* admin style  */
.sidebar {
  height: 660px;
  width: 100%;
  border-radius: 21px;
  background-color: #ffffff;
}

.admin-side-text {
  color: #272727;
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 20px;
  text-align: right;
  width: 100%;
  border-radius: 5px;
  cursor: pointer;
}

.admin-side-text:hover {
  background-color: #272727;
  color: white;
  font-size: 14px;
}
.admin-content-text {
  color: #555550;
  font-size: 21px;
  font-weight: 800;
  letter-spacing: 0;
  line-height: 28px;
  text-align: center;
}
.item-delete-edit {
  color: #979797;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 30px;
  text-align: right;
  cursor: pointer;
}

.text-form {
  color: #979797;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 20px;
  text-align: right;
}

.select {
  padding: 2px 0;
  color: #979797;
  width: 100%;
  border-radius: 8px;
}
.btn-save {
  border: none;
  color: white;
  outline: 0;
  padding: 8px 18px;
  border-radius: 4px !important;
  background-color: var(--salmon-green) !important;
  transition: var(--transition-timing) !important;
}
.btn-save:hover {
  transition: var(--transition-timing) !important;

  background: #111 !important;
}
.btn-a {
  border: none;
  color: white;
  height: 45px;
  border-radius: 9px;
  background-color: #272727;
}
.btn-a:hover {
  color: rgb(164, 164, 164);
}
/* end admin  */

/* rates  */

.rate-container {
  height: 100%;
  width: 100%;
  padding-bottom: 12px;
  border-radius: 7px;
  background-color: #ffffff;
  box-shadow: 0 1px 2px 0 rgba(151, 151, 151, 0.5);
}
.rate-count {
  color: #979797;
  font-size: 12px;
  letter-spacing: 0;
  line-height: 23px;
}
.rate-name {
  color: #555550;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 19px;
}
.rate-description {
  color: #555550;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 19px;
  text-align: right;
}
/* end rate */

/* user order */
.user-order {
  height: 100%;
  width: 100%;
  border-radius: 7px;
  background-color: #ffffff;
  box-shadow: 0 1px 2px 0 rgba(151, 151, 151, 0.5);
}
.order-title {
  color: #272727;
  font-size: 16px;
  font-weight: bold;
}

.stat {
  color: #979797;

  font-size: 16px;
  letter-spacing: 0;
  line-height: 30px;
  text-align: right;
}
/* end user order */

/* user Address */

.user-address-card {
  height: 151px;
  width: 100%;
  border-radius: 7px;

  background-color: #ffffff;
  box-shadow: 0 1px 2px 0 rgba(151, 151, 151, 0.5);
}
.btn-add-address {
  border: none;
  height: 50px;
  width: 100%;
  padding: 0 12px;
  color: white;
  border-radius: 9px;
  background-color: var(--main-color);
}
.btn-add-address:active,
.btn-add-address:focus {
  opacity: 0.7;
}
/* user address */

/* user card data */
.user-data {
  height: 270px;
  width: 100% i !important;
  border-radius: 7px;
  background-color: #ffffff;
  box-shadow: 0 1px 2px 0 rgba(151, 151, 151, 0.5);
}
/* end user data */

/* filter side style */
.filter-title {
  color: #555550;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 20px;
  text-align: right;
}
.filter-sub {
  color: #555550;
  font-size: 12px;
  letter-spacing: 0;
  line-height: 17px;
  text-align: center;
}
/* end filter */

.fSkZZn {
  height: 180px !important;
  object-fit: cover;
}

/* Form Style Login And Register */

.react-tel-input .form-control {
  border: 1px solid #111 !important;
  border-radius: 5px;
  line-height: 25px;
  height: 38px !important;
  width: 378px !important;
}
.react-tel-input {
  margin: 0 auto;
  margin-bottom: 14px;
  direction: ltr;
  width: auto !important;
}
