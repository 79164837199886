.filter-category-side {
  overflow: auto;
  height: auto;
}
.submenu-categories-slide .submenu-slide:last-child {
  padding-bottom: 8px;
}
.filter-category-side button.accordion-menu p.menu-title {
  padding: 0 !important;
  padding-top: 13px !important;
}
/* تغيير خلفية السكرول إلى #333 */
.filter-category-side::-webkit-scrollbar {
  background-color: white;

  border-radius: 7px;
}

/* تغيير لون السكرول نفسه إلى اللون الأبيض */
.filter-category-side::-webkit-scrollbar-thumb {
  background-color: rgb(213, 213, 213);
  border-radius: 25px;
}
.submenu-slide {
  font-size: 16px !important;
}
.submenu-categories-slide.active {
  max-height: 100% !important;
  visibility: visible;
}
.mobile-menu-in-slide {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.container-shop {
  width: 97%;
  max-width: 1600px;
  margin: auto;
  position: relative;
}
@media screen and (max-width: 768px) {
  .container-shop {
    width: 100%;
    max-width: 100vw;
    margin: auto;
    overflow-x: hidden;
  }
}

@media (min-width: 970px) and (max-width: 1200px) {
  .co-cont-pro {
    width: 80% !important;
    height: min-content;
  }
}

.filter-by-price {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}
.filter-by-price div {
  display: flex;
  align-items: center;
}
.filter-by-price div input {
  width: 140px;
  height: 35px;
  border-radius: 2px;
}
.filter-by-price div input:focus {
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  border-color: var(--salmon-green);
}
.side-filter {
  margin-top: 15px;
  margin-left: 20px;
  max-width: 100%;
}
.close-filter {
  display: none;
}
.show-filters {
  display: none;
}
@media (max-width: 994px) {
  .close-filter {
    position: absolute;
    top: 1%;
    left: 9%;
    display: block;
    cursor: pointer;
  }
  .show-filters {
    font-size: 17px;
    margin-top: 11px;
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
    gap: 8px;
    cursor: pointer;
  }
  .close-filter svg {
    font-size: 30px;
  }
  .side-filter {
    position: relative;

    overflow: auto;
    background: white;
    position: fixed;
    padding: 10px 22px !important;
    padding-top: 32px !important;
    border-radius: 8px;
    top: 0;
    right: -110%;
    margin: 0 !important;
    height: 100vh;
    width: 300px;
    z-index: 999999999999999;
    transition: all 0.4s;
  }
  .side-filter.activeFil {
    right: 0;
  }
}
