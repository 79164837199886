.product-grid {
  /* display: flex;*/
  grid-template-columns: 1fr;
  gap: 8px;
}

.product-grid .showcase {
  border: 1px solid #eeeeee;
  border-radius: var(--border-radius-md);
  overflow: hidden;

  margin-right:10px;
  /* background: rgb(252, 252, 253); */
  transition: var(--transition-timing);
  position: relative;
  /* box-shadow: 0 0 2px rgba(0, 0, 0, 0.1); */
}

.react-multi-carousel-track {
  padding: 10px 0;
}
.showcase .showcase-content .first-content {
  /* background: #f6f6f6; */
  width: 100%;
  padding: 1px 10px 3px 0;}
.showcase .showcase-content .first-content .cat-name {
  color: var(--salmon-green);
  font-size: 14px;
  text-align: right;
  font-weight: 500;
  margin-bottom: 3px;
}
.showcase-banner {
  margin-bottom: -5px ;
}
/* .react-multi-carousel-track > .react-multi-carousel-item {
  margin-right: 7px;
} */
.showcase .showcase-content .first-content .prod-title {
  color: #111;
  font-size: 15px;
  width: max-content;
  max-width: calc(100% - 40px);
  white-space: nowrap;
  overflow: hidden;
  font-weight: 600;
  text-overflow: ellipsis;
  border-bottom: 1px solid transparent;

}
.showcase .showcase-content .first-content .prod-title:hover {
  border-bottom: 1px solid #111;
}
.showcase .showcase-content .price {
  text-align: left;
    padding-left: 14px;
    padding-bottom: 6px;  font-size: 19px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  color: var(--salmon-green);
}
.showcase .showcase-content .price .price-after {
  font-size: 12px;
  color: #9a9a9a;
  text-decoration: line-through;
  margin-left: 3px;  
  margin-top: 7px;
}
.select-size {
  cursor: pointer;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.3);
  border-radius: 7px;
  outline: none;
  margin: 5px;
  width: max-content;
  padding: 0 12px;
  font-size: 21px;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  transition: 0.25s;
}
.color-box {
  position: relative;
  width: 38px;
  height: 38px;
  transition: 0.2s ease-in-out;
}
.color-box:hover {
  margin-top: 3px;
}
.color-name {
  transition: 0.25s;
  position: absolute;
  top: -50%;
  left: 50%;
  opacity: 0;
  transform: translate(-50%, -50%);
  background: #111;
  width: max-content;
  pointer-events: none;
  height: 26px;
  border-radius: 5px;
  z-index: 99999;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 6px;
  font-size: 13px;
  color: #fff;
}
.color-box:hover .color-name {
  opacity: 1;
}
.color-name::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  border: solid transparent;
  border-width: 8px;
  margin-left: -8px; /* نصف عرض الحدود */
  border-top-color: var(--salmon-green); /* لون السهم */
}
.select-size:hover span {
  opacity: 1;
}
.select-size:hover ~ .sizes-container {
  gap: 26px !important;
}
.select-size span {
  transition: 0.25s;
  position: absolute;
  top: -80%;
  left: 50%;
  opacity: 0;
  transform: translate(-50%, 60%);
  background: var(--salmon-green);
  width: 34px;
  height: 26px;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  color: #fff;
}
.select-size span::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  border: solid transparent;
  border-width: 8px;
  margin-left: -8px; /* نصف عرض الحدود */
  border-top-color: var(--salmon-green); /* لون السهم */
}
.select-size-cart {
  cursor: pointer;
  border: 1px solid #111 !important;
  border-radius: 7px;
  outline: none;
  margin: 5px;
  width: max-content;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 10px;
}
.rating-cont {
  position: absolute;
  display: flex;
  bottom: 15px;
  left: 4%;
  align-items: center;
  color: rgb(255, 157, 0);
}
.product-grid .showcase:hover {
  box-shadow: 0 0 12px hsla(0, 0%, 0%, 0.1);
}

.product-grid .showcase-banner {
  position: relative;
}

.product-grid .product-img {
  width: 100%;
  height: 350px;
  margin: auto;
  padding: 10px;
  border-radius: 20px;
  object-fit: cover;
  object-position: top;
  transition: var(--transition-timing);
  overflow: hidden;
}

.product-grid .product-img.default {
  position: relative;
  z-index: 1;
}

.product-grid .product-img.hover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  opacity: 0;
}

.product-grid .showcase:hover .product-img.default {
  opacity: 1;
  transform: scale(1.02);
  transition: 0.2s;
}

.loader-container {
  position: fixed;
  top: 50%;
  left: 50%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999999999999999 !important;
  background-color: #fff;
  width: 100%;
  overflow: hidden;
  transform: translate(-50%, -50%);
}
.lazy-load-image-background.blur.lazy-load-image-loaded > img {
  /* padding: 10px; */
  border-radius: 9px 0 0 0;
  transition: 0.2s !important;
}
.lazy-load-image-background {
  width: 100% !important;
}
/* .product-grid .showcase:hover .product-img.default {
  opacity: 0;
} */

/* .product-grid .showcase:hover .product-img {
  transform: scale(1.1);
} */

.product-grid .showcase-badge {
  position: absolute;
  top: 15px;
  left: 15px;
  background: var(--ocean-green);
  font-size: var(--fs-8);
  font-weight: var(--weight-500);
  color: var(--white);
  padding: 0 8px;
  border-radius: var(--border-radius-sm);
  z-index: 3;
}

.product-grid .showcase-badge.angle {
  top: 8px;
  left: -29px;
  transform: rotate(-45deg);
  text-transform: uppercase;
  font-size: 11px;
  padding: 5px 40px;
}
.product-grid .showcase-badge.angleTwo {
  top: 16px;
  left: -35px;
  transform: rotate(-45deg);
  text-transform: uppercase;
  font-size: 11px;
  padding: 5px 40px;
}

.product-grid .showcase-badge.black {
  background: var(--eerie-black);
}
.product-grid .showcase-badge.red {
  background: hsl(0deg 100% 50%);
}
.product-grid .showcase-badge.orange {
  background: hsl(36, 100%, 50%);
}

.product-grid .showcase-badge.pink {
  background: var(--salmon-green);
}

.product-grid .showcase-actions {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 20px;
  transform: translateX(50px);
  transition: var(--transition-timing);
  z-index: 3;
}

.product-grid .showcase:hover .showcase-actions {
  transform: translateX(0);
}

.product-grid .btn-action.is-fav {
  background: rgb(255, 71, 102);
  color: var(--white);
}
.product-grid .btn-action.is-fav:hover {
  background-color: rgb(255, 45, 80);
}
.product-grid .btn-action {
  background: var(--white);
  color: var(--sonic-silver);
  margin-bottom: 5px;
  border-radius: 9px !important;
  /* line-height: normal !important; */
  border: 1px solid var(--cultured);
  padding: 5px;
  border-radius: var(--border-radius-sm);
  transition: var(--transition-timing);
}
.rating-cont {
  width: 100%;
  display: block;
}
.product-grid .btn-action-card,
.to-new-url {
  background: var(--white);
  border: 1px solid var(--eerie-black);
  width: 50px;
  margin-bottom: 7px;
  display: flex;
  height: 50px !important;
  justify-content: center;
  border-radius: 31px;
  transition: var(--transition-timing);
  align-items: center;

  color: #1c1c1c;
}
.product-grid .cart-price {
  width: 100%;
  display: flex;

  justify-content: space-between;
  align-items: center;
}
.price-flex {
  display: flex;
}

.price-flex span span {
  text-decoration: line-through;
  color: #666;
  font-size: 15px;
  margin-left: 5px;
}

.product-grid .to-new-url svg {
  margin-left: 1px;
  font-size: 24px;
  font-weight: 300;
}
.product-grid .to-new-url:hover {
  background: var(--salmon-green);
  color: var(--white) !important;
  border-color: transparent;
}
.product-grid .btn-action:hover {
  background: var(--eerie-black);
  color: var(--white);
  border-color: var(--eerie-black);
}

.product-grid .showcase-category {
  color: var(--salmon-green);
  font-size: 20px;
  font-weight: var(--weight-500);
  text-transform: uppercase;
  margin: 10px 0;
}

.product-grid .showcase-title {
  color: #111;
  font-size: 16.5px;
  font-weight: 400;
  letter-spacing: 1px;
  margin-bottom: 10px;
  text-align: right;
  max-width: calc(100% - -56px);
  overflow: hidden;
  text-overflow: ellipsis;
  text-transform: capitalize;
  transition: var(--transition-timing);
  white-space: nowrap;
  width: 100%;
  height: 23px;
}

.product-grid .showcase-title:hover {
  color: #666;
  text-decoration: underline;
}

.product-grid .showcase-rating {
  display: flex;
  color: var(--sandy-brown);
  margin-bottom: 10px;
}

.product-grid .price-box {
  display: flex;
  gap: 10px;
  font-size: var(--fs-7);
  color: var(--eerie-black);
  margin-bottom: 10px;
}

.product-grid .price {
  font-weight: var(--weight-700);
}

.product-grid del {
  color: var(--sonic-silver);
}

@media (min-width: 580px) {
  .product-grid {
    grid-template-columns: 1fr 1fr !important;
    gap: 12px;
  }
  .product-grid.four-grid {
    grid-template-columns: 1fr 1fr !important;
    gap: 12px;
  }
}
@media (min-width: 768px) {
  .product-grid {
    grid-template-columns: 1fr 1fr 1fr !important;
    gap: 3px;
    row-gap: 16px;
  }
  .product-grid.four-grid {
    grid-template-columns: 1fr 1fr 1fr !important;
    gap: 12px;
  }
}

@media (min-width: 1400px) {
  .product-grid {
    grid-template-columns: repeat(4, 1fr) !important;
  }
  .product-grid.four-grid {
    grid-template-columns: repeat(4, 1fr) !important;
  }
}

.tags-product {
  width: 80%;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}
.tags-title {
  font-size: 18px;
  color: var(--salmon-green);
  margin-top: 16px;
  margin-bottom: 12px;
}
.subcat-product a {
  font-size: 11px;
  border: 1px solid #ccc;
  color: var(--eerie-black);
  padding: 6px 11px;
  border-radius: 30px;
  transition: var(--transition-timing);
}
.subcat-product a:hover {
  background: var(--salmon-green);
  color: #fff;
  transform: scale(1.05);
}

.rate-avg {
  position: absolute;
  top: 69%;
  left: 5%;
  display: flex;
  align-items: center;
  color: orange;
}

.rate-avg img {
  width: 20px;
  margin-left: 4px;
}
.rate-avg span {
  margin-top: 3px;
}

.popup {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  /* backdrop-filter: blur(2px);
  filter: blur(2px); */
  background: rgba(0, 0, 0, 0.3);
  position: fixed;
  top: 0;
  left: 0%;
  transition: 0.3s ease;
  z-index: 9999999;
  overflow: hidden !important;
}
.popup::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden !important;
  width: 100vw;
  height: 100vh;
  backdrop-filter: blur(5px);
  background: rgba(0, 0, 0, 0.3);
  z-index: -1;
}

.popup .popup-quick {
  z-index: 999999999999;
  display: flex;
  gap: 50px;
  height: 90%;
  align-items: center;
  width: 90%;
  background: #fff;
  padding: 24px 56px;
  border-radius: 13px;
  box-shadow: 0 0 15px wheat;
}
.popup-quick .product-gallery-card {
  width: max-content;
  height: min-content;
}
.rate-prod {
  display: flex;
  font-size: 18px;
  color: orange;
  align-items: center;
  margin-left: 9px;
}
.quant-style {
  color: #777;
  margin-left: 10px;
  font-size: 16px;
}
.popup-quick .product-texts {
  width: 40%;
}

@media (max-width: 1305px) {
  .popup-quick {
    z-index: 999999999999;
    display: flex;
    gap: 50px;
    height: 80%;
    align-items: center;
    width: 90%;
    flex-direction: column !important;
    background: #fff;
    padding: 14px 56px;
    border-radius: 13px;
    box-shadow: 0 0 15px wheat;
    overflow-y: scroll;
  }
  .popup-quick .product-gallery-card {
    width: 90%;
    margin: auto;
  }
  .popup-quick .product-gallery-card .thumbnail-image-container {
    margin: auto;
  }
  .popup-quick .product-texts {
    width: 90%;
    margin: auto;
  }
}
.baska-text {
  color: var(--salmon-green) !important;
  font-size: 15px !important;
}
.close-popup-quick {
  z-index: 9999999999999;
  position: absolute;
  top: 2%;
  left: 2%;
  width: 40px;
  display: flex;
  height: 40px;
  border-radius: 50%;
  color: red;
  background: #fff;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
@media (max-width: 425px) {
  .popup-quick {
    width: 100%;
  }
  .popup-quick .product-texts .price-product-single {
    font-size: 18px;
  }
  .popup-quick .product-texts .product-contact-container {
    font-size: 13px;
  }
  .popup-quick .product-texts .cat-title {
    font-size: 17px;
  }
}


.colors-product-card {
  display: flex;
  column-gap: 10px;
  justify-content: end;
  padding: 0 0 8px 13px;
}
.colors-product-card .color {
  width: 30px;
  height: 30px;
}