.favorite-cont {
    width: 95%;
    max-width: 1600px;
    margin: auto;
}
@media (max-width:768px){
    .favorite-cont {
        width: 98%;
        max-width: 100vw;
    }
}
.container-wishlist {
    width: 100%;
    margin: auto;
    overflow-x: auto; /* يضيف شريط التمرير الأفقي في حال كانت الجدول أوسع من الشاشة */
    display: inline-block; /* يحول العنصر إلى عنصر مستطيل ليس استمراريا */

}

.container-wishlist table {
    border-collapse: collapse; /* يجمع حدود الخلايا في جدول واحدة */
    width: 100%;
    overflow-x: auto; /* يضيف شريط التمرير الأفقي في حال كانت الجدول أوسع من الشاشة */
    max-width: 100%; /* حد أقصى لعرض الجدول يتأقلم مع حجم الشاشة */

}
.container-wishlist table tbody td,
.container-wishlist table thead th {
    white-space: nowrap; /* منع الكلمات من الانقسام */
}
.container-wishlist table thead {
    /* display: table; */
    width: 100%;
}

.container-wishlist table thead th {
    padding: 10px;
    border-top: 2px solid #222;
    border-bottom: 2px solid #222;
    text-align: center; /* ضبط نص العناصر في الـ td إلى اليسار */
    
}

.container-wishlist table tbody td {
    border-top: 2px solid #222;
    border-bottom: 2px solid #222;    padding: 5px;
    
    text-align: center; /* ضبط نص العناصر في الـ td إلى اليسار */
}
.container-wishlist table tbody td img {
    width: 120px;
    max-height: 120px;
    object-fit: cover;
    margin: auto;
}

.wishlist-title {
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: #4a4a4a;
    margin-bottom: 12px;
}
.wishlist-title svg {
    font-size: 41px;
    margin-bottom: 12px;
    color: #4a4a4a;
}