.user-info {
  background: #fff;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.1);
  border: 0.2px solid #e9e9e9;
  border-radius: 25px;
  padding: 0 25px;
  padding-top: 26px;
  padding-bottom: 80px;
  display: flex;
  flex-direction: column;
  gap: 15px;
}
.user-info h2 {
  text-align: center;
  padding-bottom: 45px;
}
.user-info .form-user {
  display: flex;
  align-items: center;

  width: 100%;
  justify-content: center;
  flex-wrap: wrap;
  gap: 30px;
}
.user-info .form-user div {
  width: 48%;
  display: flex;
  gap: 16px;
}
.user-info .form-user div label {
  width: max-content;
  white-space: nowrap;
  display: flex;
  justify-content: center;
  align-items: center;
}
.user-info .form-user div input {
  padding: 13px 25px;
  width: 100%;
  border-radius: 25px;
  border: #d8d8d8 1px solid;
  transition: 0.2s all ease;
  outline: 0;
}
.user-info .form-user div input:focus,
.user-info .form-user div input:active {
  border-color: var(--salmon-green);
}
.user-info .form-user div.btn-div {
  display: flex;
  justify-content: end;
}
.user-info .form-user .btn-edit-profile {
  width: 86%;
  display: flex;
  justify-content: center;
  align-items: center;

  height: 50px;
  border-radius: 25px;

  background: var(--salmon-green);
  border: 0;
  outline: 0;
  color: #fff;
  transition: background-color 0.3s ease, transform 0.3s ease; /* إضافة تأثير عند التمرير والنقر */
}
.user-info .btn-edit-profile:hover {
  transform: scale(1.01);
  background: #3a3a3a;
}
.user-info .btn-edit-profile:active {
  transform: scale(0.95);
  background: #000000;
}

.change-password-form {
  margin: auto;
  text-align: center;
}
input.change-password-form {
  background: #fff;
  border-radius: 2px;
  padding: 10px 16px !important;
  border: 0.5px solid #ccc;
  outline: 0;
  text-align: right;
}
.stop-btn-save {
  pointer-events: none;
  opacity: 0.7;
}
.reset-password-profile {
  background: #fff;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.1);
  border: 0.2px solid #e9e9e9;
  border-radius: 4px;
  text-align: center !important;
  display: flex !important;
  align-items: center !important;
  padding: 25px !important;
  row-gap: 25px;
  flex-direction: column;
}
.close-sidebar {
  display: none;
}
.menu-ham {
  display: none;
}
@media (max-width: 850px) {
  .menu-ham {
    display: block;
    /* position: absolute  ; */
    top: 22%;
    color: #6d6d6d;
    opacity: 0.9;
    cursor: pointer;
    right: 0;
    transform: translate(0, -40%);
    background: #fff;
    padding: 10px 16px;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.1);
    border-radius: 12px 0 0 12px;
    transition: 0.3s ease-in;
  }
  .menu-ham:hover {
    color: #000000;
    opacity: 1;
  }
  .sidebar {
    transition: 0.4s ease;
    position: fixed;
    width: 300px;
    max-width: 100vw;
    background: #fff;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);
    top: 0;
    right: -150%;
    z-index: 9999;
    height: 100vh;
    padding-top: 50px;
  }
  .sidebar.show {
    right: 0;
  }
  .close-sidebar {
    display: block;
    position: absolute;
    top: 3%;
    left: 8%;
    cursor: pointer;
  }
  .close-sidebar svg {
    font-size: 30px;
  }
  .user-info {
    display: block;
  }
  .user-info div {
    width: 100%;
  }
}
@media (max-width: 1156px) {
  .user-info .form-user div {
    width: 90%;
    flex-direction: column;
  }

  .user-info .form-user .btn-edit-profile {
    width: 100%;
  }
}
@media (max-width: 700px) {
  .user-info .form-user div {
    display: flex;
    gap: 16px;
    width: 100%;
    flex-direction: column;
  }
  .user-info .form-user .btn-edit-profile {
    width: 100%;
  }
}
