.category { margin-bottom: 30px; }
.category .container-cat .category-title-up {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 25px auto !important;
    width:90% !important;
}
.container-cat {
  margin: auto !important;
  width: 98% !important;

}
.category-title-up h4{
  position: relative;
  font-size: 23px ;
}
.category-title-up h4::before{
  content: '';
  position: absolute;
  right: 0;
  bottom: -11px;
  background: var(--eerie-black);
  width: 80%;
  height: 2px;
}
 .btn-morea {
  max-width: 100%;
    background: var(--salmon-green);
    color: var(--white);
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
    font-size: var(--fs-7);
    font-weight: var(--weight-500);
    text-transform: uppercase;
    padding: 4px 16px;
    -webkit-border-radius: var(--border-radius-sm);
    border-radius: var(--border-radius-sm);
    -webkit-transition: var(--transition-timing);
    -o-transition: var(--transition-timing);
    transition: var(--transition-timing);
}
 .btn-morea:hover {
    background: var(--eerie-black);
}
 .btn-more  a{
  max-width: 100%;
    background: var(--salmon-green);
    color: var(--white);

    border-radius: 50px !important;
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
    font-size: var(--fs-7);
    font-weight: var(--weight-500);
    text-transform: uppercase;
    padding: 6px 20px;
    -webkit-transition: var(--transition-timing);
    -o-transition: var(--transition-timing);
    transition: var(--transition-timing);
}
 .btn-more a:hover {
    background: var(--eerie-black);
}
.category-item-container {
  display: flex;
  align-items: center;
margin: auto;
  width: 90% !important;
  gap: 10px;
  overflow: auto hidden;
  scroll-snap-type: inline mandatory;
  overscroll-behavior-inline: contain;
}

.category-item {
  min-width: 215px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 10px;
  position: relative;
  padding: 5px;
  height: 158px;
  border: 1px solid var(--cultured);
  border-radius: var(--border-radius-md);
  scroll-snap-align: start;
  transition: var(--transition-timing);
}
.category-item .lazy-load-image-background.blur.lazy-load-image-loaded > img  {
  padding: 0 !important;

}
.category-item .lazy-load-image-background.blur.lazy-load-image-loaded {
  display: flex !important;
    justify-content: center;
    align-items: center;
    height: 100% !important;
}
.category-item:hover {
  box-shadow: 0 0 8px hsla(0,0%,0%,0.05);
  border-color:var(--salmon-green);
}
.category-item img {
  border: 1px solid hsl(0, 0%, 80%);
  aspect-ratio: 16 / 9;
  object-fit: cover;
  height: 89px;
  object-position: center;
  clip-path: circle();
}
.category-img-box img {
  height: 75px;
  aspect-ratio: 16 / 9;
  object-fit: cover;
  object-position: center;

}
.category-content-box { 
  width: 100%; 
  display: flex;
  justify-content: center;
  align-items: center;
}



.category-item-title {
  color: var(--eerie-black);
  font-size:16px;
  font-weight: 600;
  text-transform: uppercase;
}

.category-item-amount {
  color: var(--sonic-silver);
  font-size: var(--fs-11);
  position: absolute;
  top: 4%;
  left: 1%;
}

.category-btn {
  color: var(--salmon-green);
  font-size: var(--fs-9);
  font-weight: var(--weight-500);
  text-transform: capitalize;
}

@media (min-width: 1400px) {

    /**
     * #BASE 
     */
  
    html { font-size: 18px; }
  
  
  
    /**
     * #REUSED STYLE 
     */
  
    .container-cat { max-width: 1350px; }

}
@media (min-width: 1200px) {

    /**
     * #REUSED STYLE 
     */
  
    .container-cat { max-width: 1200px; }
  
  
  
    /**
     * #HEADER
     */
  
    .desktop-menu-category-list { gap: 45px; }
    /* .category-item { min-width: calc(25% - 22.5px); } */

  .category-item-title { --fs-9: 0.824rem;
  overflow: hidden;
text-overflow: ellipsis;
white-space: nowrap; }
}


@media (min-width: 1024px) {
/* .category-item { min-width: calc(40% - 22.5px); } */

.category-img-box { padding: 3px; }
}