.payment-method {
    display:flex;
    align-items: center;
    width:80%;
    justify-content: start;
    flex-direction: row-reverse;
    gap: 15px;
}
.payment-method .mar {
    margin-top: 25px;
}
.payment-method div {
    width: 100%;
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}
.payment-method div input {
    width: 10%;
    margin-bottom: 5px;
        cursor: pointer;
}
.payment-method label {
        cursor: pointer;
    width: 70%;
}