.popup-cats {
    opacity: 0;
    width: 0;
    transition: 0.3s ease;
}
.popup-cats.active {
    position: fixed;
    top: 0;
    right: 0;
    width: 300px;
    opacity: 1;
    height: 100vh;
    background-color: #fff;
    box-shadow: 0 0 9px rgba(0, 0, 0, 0.368);
    z-index: 999999;
    padding: 25px 5px;
    overflow-y: auto;

}
.popup-cats #cont-rel {
    position: relative;

}
.popup-cats h4 {
    text-align: center;
    margin-bottom: 35px;
    color: var(--main-color);
    font-weight: 800;
}
.popup-cats ul li {
    background: #fff;
    box-shadow: 0 0 7px rgba(0, 0, 0, 0.2) !important;
    padding: 10px 18px;
    padding-left: 0;
    border-radius: 6px;
    transition: 0.2s linear;
    margin-bottom: 11px;
}
.popup-cats ul li:hover {
    background: var(--main-color);
    
}
.popup-cats ul li:hover a{
    color: #fff !important;    
}
.popup-cats ul li a {
color: #111;
}
.popup-cats .close-popup-cats {
    position: absolute;
    top: 1%;
    left: 2%;
    background: #fff;
    box-shadow: inset 0 0 12px rgba(0, 0, 0, 0.2);
    color: red;
    border-radius: 50%;
    width: 45px;
    height: 45px;
    display: flex;
    cursor: pointer;
    justify-content: center;
    align-items: center;
}
.popup-cats .close-popup-cats svg {
    font-size: 23px;
}