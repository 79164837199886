.userImg-sidebar {
  width: 50px;
  border-radius: 50%;
  border: 1px solid #eee;
}
.userSide img {
  background: #fff;
}
.sidebarNew {
  height: max-content;
  width: 80px;
  padding: 18px 6px;
  background: #fff;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);
  border-radius: 15px;
  position: relative;

  padding: 0.4rem 0.8rem;
  z-index: 9999 !important;  gap: 25px;
  display: flex;
  flex-direction: column;
  transition: 0.5s all ease;
}
.noSide {
    width:80px;
}
@media (max-width: 850px) {
  .sidebarNew {
    position: fixed;
    top: 0;
    right: -80%;
    height: 100vh;
  }
  .sidebarNew.active {
    right: 0;
    width: 300px !important;
  }
}
.sidebarNew.active ~ .main-content {
  right: 250px;
  width: calc(100%-250px);
}
.sidebarNew.active {
  width: 100%;
}

.sidebarNew .btn-link-side {
  position: absolute;
  color: #12171e;
  top: 1.4rem;
  right: 50%;
  transform: translateX(50%);
  font-size: 28px;
  line-height: 50px;
  cursor: pointer;
}
.sidebarNew.active .btn-link-side {
  right: 90%;
}
.sidebarNew .top .logo {
  color: #12171e;
  display: flex;
  height: 50px;
  width: 100%;
  align-items: center;
  gap: 10px;
  margin: 16px 4px;
  pointer-events: none;
  opacity: 0;
}
.sidebarNew .top .logo img {
  width: 50px;
  border-radius: 50%;
  height: auto;
}
.sidebarNew.active .top .logo {
  opacity: 1;
}
.userSide {
  display: flex;
  align-items: center;
  margin: 1rem 0;
}
.userSide p {
  color: #12171e;
  opacity: 1;
  margin-right: 1rem;
  margin-bottom: 0 !important;
}

.bold-sidebar {
  font-weight: bolder;
}
.sidebarNew p {
  opacity: 0;
}
.sidebarNew.active p {
  opacity: 1;
}  
.sidebarNew ul {
  display: flex;
  flex-direction: column;
  gap: 12px;
  z-index: 9999 !important;
}
.sidebarNew ul li {
  position: relative;
  list-style-type: none;
  height: 50px;
  width: 99%;
  margin: 0.8rem auto;
  z-index: 9999 !important;
  display: flex;

}
.sidebarNew .navItem-sidebar {
  opacity: 0;
  visibility: hidden;
  font-size: 16px;
}
.sidebarNew.active .navItem-sidebar {
  opacity: 1;
  padding-right: 10px;
  visibility: visible;
}
.sidebarNew ul li a {
  color: #12171e;
  display: flex;
  align-items: center;
  text-decoration: none;
  border-radius: 0.8rem; 
  pointer-events: all;


}
.sidebarNew.active ul li a {
  padding: 5px 15px;
  height: 60px;transition: 0.3s ease;

}
.sidebarNew ul li a:hover {
  background: #12171e;
  color: #fff;
  width: 50px;
}
.sidebarNew.active ul li a:hover {
  background: var(--salmon-green);
  color: #fff;
  width: max-content;
  height: 60px;
  padding: 5px 15px;
}
.sidebarNew ul li a svg {
  min-width: 50px;
  text-align: center;
  height: 50px;
  border-radius: 12px;
  line-height: 50px;
}
.sidebarNew.active ul li a svg {
  min-width: 40px;
  text-align: center;
  height: 40px;
  border-radius: 12px;
  line-height: 50px;
}

.sidebarNew ul li .tooltip {
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 0 0.5rem 0.8rem rgba(0, 0, 0, 0.2);
  border-radius: 0.6rem;
  padding: 0.4rem 1.2rem;
  line-height: 1.8rem;
  background: #fff;
  z-index: 20;
 opacity: 0;
  width: max-content;
}

.sidebarNew ul li:hover .tooltip {
  opacity:1;
}
.sidebarNew.active ul li:hover .tooltip {
  display: none;
}
